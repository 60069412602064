import { createActionCreator, createReducer } from "deox";
import { access } from "fs";
import { produce } from "immer";
import { UploadFile } from "../../models/misc";
import {
  IAccessUser,
  IMarkupUser,
  IRegisterRequest,
  IUpsertAddressRequest,
  IUserAccess,
  IUserAddressWithEmail,
  IUserMarkup,
  IUserProfile,
  IUserVendor,
  UserRole,
} from "../../models/users";
import * as authService from "../../services/auth";
import { dataUrlToBase64 } from "../../utils";
import { withErrorDispatch } from "./withErrorDispatch";
export type State = IUserProfile[];

const changeUserBlockStatus = Object.assign(
  (userId: string, blocked: boolean, blockedUntil?: Date) =>
    withErrorDispatch(
      authService.changeUserBlockedStatus(userId, blocked, blockedUntil),
      () => changeUserBlockStatus.success({ userId, blocked, blockedUntil }),
      "Error changing user block status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/USERS/CHANGE_USER_BLOCK_STATUS",
      resolve => (blockUser: { userId: string; blocked: boolean; blockedUntil?: Date }) => resolve(blockUser)
    ),
  }
);

const changeUserRole = Object.assign(
  (userId: string, newRole: UserRole | null) =>
    withErrorDispatch(
      authService.changeUserRole(userId, newRole),
      () => changeUserRole.success({ userId, role: newRole || undefined }),
      "Error changing user role"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/USERS/CHANGE_ROLE",
      resolve => (changeRole: { userId: string; role: UserRole | undefined }) => resolve(changeRole)
    ),
  }
);

const changeUserStatus = Object.assign(
  (userId: string, approved: boolean) =>
    withErrorDispatch(
      authService.changeUserStatus(userId, approved),
      () => changeUserStatus.success({ userId, approved }),
      "Error changing user status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/USERS/CHANGE_USER_STATUS",
      resolve => (approveUser: { userId: string; approved: boolean }) => resolve(approveUser)
    ),
  }
);

const createUser = Object.assign(
  (request: IRegisterRequest) =>
    withErrorDispatch(authService.register(request), result => createUser.success(result), "Error creating user"),
  {
    success: createActionCreator("@@ADMIN/USERS/CREATE_SUCCESS", resolve => (user: IUserProfile) => resolve(user)),
  }
);

const editAccount = Object.assign(
  (userId: string, addressId: number, address: IUpsertAddressRequest) =>
    withErrorDispatch(
      authService.updateAddress(addressId, address, userId),
      result => editAccount.success({ userId, address: result }),
      "Error editing user details"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/USERS/EDIT/BASE_SUCCESS",
      resolve => (result: { userId: string; address: IUserAddressWithEmail }) => resolve(result)
    ),
  }
);

const suspendUser = Object.assign(
  (userId: string, vendors: string[], discount: boolean) => {

    return withErrorDispatch(
      authService.suspendUser(userId, vendors, discount),
      result => suspendUser.success({ userId, vendors, discount }),
      "Error suspending user"
    );
  },
  {
    success: createActionCreator(
      "@@ADMIN/USERS/SUSPEND/BASE_SUCCESS",
      resolve => (result: { userId: string, vendors: string[], discount: boolean }) => resolve(result)
    )
  }
);


const resumeVendorUser = Object.assign(
  (userId: string, vendors: string[]) =>
    withErrorDispatch(
      authService.resumeVendorUser(userId, vendors),
      result => resumeVendorUser.success({ userId, vendors }),
      "Error resuming vendor on user"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/USERS/Resume/BASE_SUCCESS",
      resolve => (result: { userId: string, vendors: string[] }) => resolve(result)
    )
  }
)

const fetchUsers = Object.assign(
  () => withErrorDispatch(authService.getAllUsers(), result => fetchUsers.success(result), "Error fetching users"),
  {
    success: createActionCreator("@@ADMIN/USERS/FETCH_SUCCESS", resolve => (users: IUserProfile[]) => resolve(users)),
  }
);

const upsertAgentLogo = Object.assign(
  (userId: string, image: UploadFile) => {
    image.content = dataUrlToBase64(image.content);

    return withErrorDispatch(
      authService.upsertAgentLogo(userId, image),
      url => upsertAgentLogo.success({ userId, url }),
      "Error updating agent logo"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/UPSER_LOGO", resolve => (result: { userId: string; url: string }) =>
      resolve(result)
    ),
  }
);

const upsertAgentBackground = Object.assign(
  (userId: string, image: UploadFile) => {
    image.content = dataUrlToBase64(image.content);

    return withErrorDispatch(
      authService.upsertAgentBackground(userId, image),
      url => upsertAgentBackground.success({ userId, url }),
      "Error updating agent background"
    );
  },
  {
    success: createActionCreator(
      "@@ADMIN/USERS/UPSER_BACKGROUND",
      resolve => (result: { userId: string; url: string }) => resolve(result)
    ),
  }
);

const updatePrimaryColor = Object.assign(
  (userId: string, color: string) => {
    return withErrorDispatch(
      authService.updatePrimaryColor(userId, color),
      color => updatePrimaryColor.success({ userId, color }),
      "Error updating agent primary color"
    );
  },
  {
    success: createActionCreator(
      "@@ADMIN/USERS/UPDATE_PRIMARY_COLOR",
      resolve => (result: { userId: string; color: string }) => resolve(result)
    ),
  }
);

const updateUserDomain = Object.assign(
  (userId: string, domain: string) => {
    return withErrorDispatch(
      authService.updateUserDomain(userId, domain),
      domain => updateUserDomain.success({ userId, domain }),
      "Error updating agent domain"
    );
  },
  {
    success: createActionCreator(
      "@@ADMIN/USERS/UPDATE_DOMAIN",
      resolve => (result: { userId: string; domain: string }) => resolve(result)
    ),
  }
);

const addUserMarkup = Object.assign(
  (
    userId: string,
    caratMin: number | null | undefined,
    caratMax: number | null | undefined,
    percent: number | null | undefined,
    priceMin: number | null | undefined,
    priceMax: number | null | undefined
  ) => {
    return withErrorDispatch(
      authService.addUserMarkup(userId, caratMin, caratMax, priceMin, priceMax, percent),
      markup => addUserMarkup.success({ markup }),
      "Error adding user markup"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/ADD_USER_MARKUP", resolve => (result: { markup: IUserMarkup }) =>
      resolve(result)
    ),
  }
);

const addUserAccess = Object.assign(
  (userId: string, pageId: string[]) => {
    return withErrorDispatch(
      authService.addUserAccess(userId, pageId),
      access => addUserAccess.success({ access }),
      "Error adding user access"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/ADD_USER_ACCESS", resolve => (result: { access: IUserAccess }) => {
      return resolve(result);
    }),
  }
);

const deleteUserMarkup = Object.assign(
  (markupId: number, userId: string) => {
    return withErrorDispatch(
      authService.deleteUserMarkup(markupId, userId),
      markupUser => deleteUserMarkup.success({ markupUser }),
      "Error deleting user markup"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/DELETE_USER_MARKUP", resolve => (result: { markupUser: IMarkupUser }) =>
      resolve(result)
    ),
  }
);

const deleteUserAccess = Object.assign(
  (accessId: number, userId: string) => {
    return withErrorDispatch(
      authService.deleteUserAccess(accessId, userId),
      accessUser => deleteUserAccess.success({ accessUser }),
      "Error deleting user markup"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/DELETE_USER_ACCESS", resolve => (result: { accessUser: IAccessUser }) =>
      resolve(result)
    ),
  }
);

const deleteUser = Object.assign(
  (userId: string) => {
    return withErrorDispatch(
      authService.deleteUser(userId),
      userIdResponse => deleteUser.success({ userIdResponse }),
      "Error deleting user"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/DELETE_USER", resolve => (result: { userIdResponse: string }) =>
      resolve(result)
    ),
  }
);

const addUserVendor = Object.assign(
  (userId: string, vendor: string) => {
    return withErrorDispatch(
      authService.addUserVendor(userId, vendor),
      response => addUserVendor.success({ userId, vendor: response.vendor }),
      "Error adding user vendor"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/ADD_USER_VENDOR", resolve => (result: { userId: string, vendor: string }) =>
      resolve(result)
    ),
  }
);

const deleteUserVendor = Object.assign(
  (userId: string, vendor: string) => {
    return withErrorDispatch(
      authService.deleteUserVendor(userId, vendor),
      vendorUser => deleteUserVendor.success({ vendorUser }),
      "Error deleting user vendor"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/DELETE_USER_VENDOR", resolve => (result: { vendorUser: { userId: string, vendor: string } }) =>
      resolve(result)
    ),
  }
);

const suspendCell = Object.assign(
  (userId: string, cell: string) => {
    return withErrorDispatch(
      authService.suspendUserCell(userId, cell),
      response => suspendCell.success({ userId, cell: response.cell }),
      "Error suspending user cell"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/SUSPEND_USER_CELL", resolve => (result: { userId: string, cell: string }) =>
      resolve(result)
    ),
  }
);

const resumeCell = Object.assign(
  (userId: string, cell: string) => {
    return withErrorDispatch(
      authService.resumeUserCell(userId, cell),
      response => resumeCell.success({ response }),
      "Error resume user cell"
    );
  },
  {
    success: createActionCreator("@@ADMIN/USERS/RESUME_USER_CELL", resolve => (result: { response: { userId: string, cell: string } }) =>
      resolve(result)
    ),
  }
);


const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchUsers.success, (state, action) => action.payload),
  handleAction(changeUserStatus.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.approved = action.payload.approved);
    })
  ),
  handleAction(changeUserBlockStatus.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      if (user) {
        user.blocked = action.payload.blocked;
        user.blockedUntil = action.payload.blockedUntil;
      }
    })
  ),
  handleAction(editAccount.success, (state, action) =>
    produce(state, draft => {
      const businessTypes = ["Diamonds Wholesaler", "Retailer", "Jewelry Designer", "Private Investor"];
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.addresses[0] = action.payload.address);
      user && (user.email = action.payload.address.email);
      user && (user.businessType.id = action.payload.address.businessTypeId);
      user && (user.businessType.name = businessTypes[action.payload.address.businessTypeId - 1]);
    })
  ),
  handleAction(suspendUser.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
    })
  ),
  handleAction(resumeVendorUser.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
    })
  ),
  handleAction(createUser.success, (state, action) =>
    produce(state, draft => {
      draft.push(action.payload);
    })
  ),
  handleAction(changeUserRole.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.role = action.payload.role);
    })
  ),
  handleAction(upsertAgentLogo.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.logoUrl = action.payload.url);
    })
  ),
  handleAction(upsertAgentBackground.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.backgroundUrl = action.payload.url);
    })
  ),
  handleAction(updatePrimaryColor.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.primaryColor = action.payload.color);
    })
  ),
  handleAction(updateUserDomain.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      user && (user.domain = action.payload.domain);
    })
  ),
  handleAction(addUserMarkup.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.markup.userId);
      user && (user.markups = [...user.markups, action.payload.markup]);
    })
  ),
  handleAction(addUserAccess.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.access.userId);
      user && (user.accesses = [...user.accesses, action.payload.access]);
    })
  ),
  handleAction(deleteUserMarkup.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.markupUser.userId);
      user && (user.markups = user.markups.filter(m => m.id !== action.payload.markupUser.markupId));
    })
  ),
  handleAction(deleteUserAccess.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.accessUser.userId);
      user && (user.accesses = user.accesses.filter(m => m.id !== action.payload.accessUser.accessId));
    })
  ),
  handleAction(deleteUser.success, (state, action) =>
    produce(state, draft => {
      const newUserArray = state.filter(d => d.id !== action.payload.userIdResponse);
      draft = newUserArray;
      return draft;
    })
  ),

  handleAction(addUserVendor.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      if (user) {
        user.vendors = user.vendors || [];
        user.vendors.push(action.payload.vendor);
      }
    })
  ),

  handleAction(deleteUserVendor.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.vendorUser.userId);
      if (user) {
        user.vendors = user.vendors.filter(v => v !== action.payload.vendorUser.vendor);
      }
    })
  ),

  handleAction(suspendCell.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.userId);
      if (user) {
        user.domainCells = user.domainCells || [];
        user.domainCells.push(action.payload.cell);
      }
    })
  ),

  handleAction(resumeCell.success, (state, action) =>
    produce(state, draft => {
      const user = draft.find(u => u.id === action.payload.response.userId);
      if (user) {
        user.vendors = user.domainCells.filter(v => v !== action.payload.response.cell);
      }
    })
  ),

]);

const actions = {
  createUser,
  changeUserBlockStatus,
  changeUserRole,
  changeUserStatus,
  editAccount,
  fetchUsers,
  // fetchAccesses,
  upsertAgentLogo,
  upsertAgentBackground,
  updatePrimaryColor,
  updateUserDomain,
  addUserMarkup,
  addUserAccess,
  addUserVendor,
  deleteUserMarkup,
  deleteUserAccess,
  deleteUser,
  deleteUserVendor,
  suspendUser,
  resumeVendorUser,
  suspendCell,
  resumeCell
};

export { actions, reducer };
