import _ from "lodash";
import comingSoon from "../images/coming_soon.jpg";
import comingSoonRound from "../images/comingSoonRound.jpeg";
import genericAsscher from "../images/generic-as.jpeg";
import genericCushionBrilliant from "../images/generic-cb.jpeg";
import genericCushion from "../images/generic-cu.jpeg";
import genericEmerald from "../images/generic-em.jpeg";
import genericHeart from "../images/generic-hs.jpeg";
import genericMarquise from "../images/generic-mq.jpeg";
import genericOval from "../images/generic-ov.jpeg";
import genericPrincess from "../images/generic-prin.jpeg";
import genericPear from "../images/generic-ps.jpeg";
import genericSpecial from "../images/generic-special.jpeg";
import genericRadiant from "../images/generic-rad.jpeg";
import * as data from "../store/data";
import { State as DataState } from "../store/data";
import * as wishList from "../store/wishList";
import { OrderItemViewModel, OrderViewModel } from "./orders";
import {
  Diamond,
  DiamondStatusEnum,
  DiamondsType,
  MeleeColor,
  MeleeStone,
  NameDiamond,
  Product,
  ProductType,
} from "./products";
import { IUserProfile, User } from "./users";
import { ApplicationState } from "../store/store";
import { getProduct } from "../selectors";
import { AccomType } from "../pages/category/fancy-shape-lines";
import { SendTwoTone } from "@material-ui/icons";
import { State } from "../store/misc";
import { useShortenUrl } from "react-shorten-url";
import { DomainLayout } from "../store/domain";
import { useSelector } from "react-redux";

const calculateMeleePrice = (melee: MeleeStone, numberOfStones: number, carats: number): number => {
  if (numberOfStones) {
    return numberOfStones * melee.carats * melee.pricePerCarat;
  }

  if (carats) {
    return carats * melee.pricePerCarat;
  }

  return 0;
};

const renderMeleeSize = (from: number, to: number): string => {
  return from === to ? `${from.toFixed(2)} mm` : `${from.toFixed(2)} - ${to.toFixed(2)} mm`;
};

const renderOrderItemDescription = (item: OrderItemViewModel): string | null | undefined => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondName(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeName(melee, item.description);
  }
};

const renderOrderItemStockId = (item: OrderItemViewModel): string | null | undefined => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondStockId(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeStockId(melee);
  }
  if (item.type === ProductType.NameDiamond) {
    return "NYD";
  }
};

const renderOrderItemShape = (item: OrderItemViewModel): string | null | undefined => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondShape(diamond);
  }
  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeShape(melee);
  }
  if (item.type === ProductType.NameDiamond) {
    const nyd = item.item as NameDiamond;
    return nyd.letter;
  }
};

const renderOrderItemPPC = (item: OrderItemViewModel): string | null | undefined | number => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondPPC(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleePPC(melee);
  }
};

const renderOrderItemColor = (item: OrderItemViewModel): any => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondColor(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeColor(melee);
  }

  if (item.type === ProductType.NameDiamond) {
    const nyd = item.item as NameDiamond;
    return nyd.color;
  }
};

const renderOrderItemClarity = (item: OrderItemViewModel): string | null | undefined | number => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondClarity(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeClarity(melee);
  }

  if (item.type === ProductType.NameDiamond) {
    return "N/A";
  }
};

const renderOrderItemWeight = (item: OrderItemViewModel): number | any => {
  if (!item?.item) {
    return 0;
  }

  if (item?.type === ProductType.Diamond) {
    const diamond = item?.item as Diamond;
    return getDiamondWeight(diamond);
  }

  if (item?.type === ProductType.Melee) {
    const melee = item?.item as MeleeStone;
    return getMeleeWeight(melee, item);
  }

  if (item?.type === ProductType.NameDiamond) {
    const nyd = item?.item as NameDiamond;
    return nyd.size;
  }
};

const renderOrderItemMeasurements = (item: OrderItemViewModel): string | null | undefined | number => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondMeasurements(diamond);
  }

  if (item.type === ProductType.Melee) {
    const melee = item.item as MeleeStone;
    return getMeleeMeasurements(melee);
  }
};

const renderOrderItemQty = (item: OrderItemViewModel): number | any => {
  if (!item?.item) {
    return 0;
  }

  if (item?.type === ProductType.Diamond) {
    const diamond = item?.item as Diamond;

    return getDiamondQTY(diamond);
  }
  if (item?.type === ProductType.Melee) {
    const melee = item?.item as MeleeStone;
    return getMeleeQTY(melee, item);
  }
};

const renderOrderItemLab = (item: OrderItemViewModel): string | null | undefined | number => {
  if (!item.item) {
    return null;
  }

  if (item.type === ProductType.Diamond) {
    const diamond = item.item as Diamond;
    return getDiamondLab(diamond);
  }

  if (item.type === ProductType.Melee) {
    return "None";
  }

  if (item.type === ProductType.NameDiamond) {
    return "None";
  }
};

const renderOrderItemUrl = (item: OrderItemViewModel): string => {
  if (!item.item) {
    return "";
  }

  if (item.type === ProductType.Melee) {
    return "";
  }
  const diamond = item.item as Diamond;
  return getDiamondUrl(diamond);
};
const getDiamondName = (diamond: Diamond): string =>
  `Diamond ${diamond.stockId} ${diamond.shape} ${diamond.carats}ct. $${diamond.pricePerCarat}/ct.`;

const getDiamondStockId = (diamond: Diamond | null): string => diamond!.stockId;
const getMeleeStockId = (melee: MeleeStone | null): string => "MELEE";
const getDiamondShape = (diamond: Diamond | null): string => diamond!.shape;
const getMeleeShape = (melee: MeleeStone | null): string => "Round";
const getMeleeName = (melee: MeleeStone, description?: string): string =>
  `Melee ${MeleeColor[melee.color]} ${renderMeleeSize(melee.sizeFrom, melee.sizeTo)} ${description || ""}`;
const getDiamondPPC = (diamond: Diamond | null): string => `$${diamond!.pricePerCarat}/ct.`;
const getMeleeColor = (melee: MeleeStone): string => MeleeColor[melee.color];
const getMeleeQTY = (item: MeleeStone | null, parentItem: OrderItemViewModel): number => {
  const carats = parentItem.totalPrice / item!.pricePerCarat;
  return carats / item!.carats;
};
const getMeleePPC = (melee: MeleeStone): string => `$${melee.pricePerCarat}/ct.`;
const getDiamondClarity = (diamond: Diamond | null): string =>
  diamond?.clarity ? (diamond.clarity === "NA" ? "N/A" : diamond.clarity) : "";
const getMeleeClarity = (melee: MeleeStone): string => "N/A";
const getDiamondWeight = (diamond: Diamond): number => diamond.carats;
const getMeleeWeight = (melee: MeleeStone, parentItem: OrderItemViewModel): number => {
  const carats = parentItem.totalPrice / melee!.pricePerCarat;
  const qty = carats / melee!.carats;
  return melee.carats * qty;
};

const getDiamondMeasurements = (diamond: Diamond): string => `${diamond.length}x${diamond.width}x${diamond.height}`;

const getMeleeMeasurements = (melee: MeleeStone): string =>
  renderMeleeSize(melee!.sizeFrom, melee!.sizeTo).slice(0, -2);

const getDiamondLab = (diamond: Diamond): string => diamond.lab;

const getDiamondUrl = (diamond: Diamond): string => (diamond.certificateUrl ? diamond.certificateUrl : "None");

const getDiamondQTY = (diamond: Diamond): number => {
  return diamond?.parcelStones ? diamond.parcelStones : 1;
};

export const filterDiamondsImage = (dataState: data.State, diamond: DiamondsType): any => {
  const colors = dataState.colors.filter(c => c.name === "White");
  const NaColor = dataState.colors.find(c => c.name === "NA");
  const NaClarity = dataState.clarities.find(c => c.name === "NA");
  const shape = dataState.shapes.find(s => s.id === diamond?.shapeId);

  if (
    colors?.some(c => c.id === diamond?.colorId) &&
    !(diamond?.colorId === NaColor?.id && diamond?.clarityId === NaClarity?.id)
  ) {
    if (diamond.shapeId === 11) {
      return comingSoonRound; //new
    } else if (diamond.shapeId === 4) {
      return genericAsscher; //new
    } else if (diamond.shapeId === 12) {
      return genericMarquise; //new
    } else if (diamond.shapeId === 7) {
      return genericCushion; //new
    } else if (diamond.shapeId === 9) {
      return genericEmerald; //new
    } else if (diamond.shapeId === 3) {
      return genericHeart; //new
    } else if (diamond.shapeId === 5) {
      return genericPear; //new
    } else if (diamond.shapeId === 6) {
      return genericCushionBrilliant; //new
    } else if (diamond.shapeId === 10) {
      return genericPrincess; //new
    } else if (diamond.shapeId === 1) {
      return genericOval; //new
    } else if (diamond.shapeId === 8) {
      return genericRadiant;
    } else {
      return genericSpecial;
    }
  } else {
    return comingSoon;
  }
};
// const checkIfPicOrVideoExists = (product: Product<DiamondsType>) => {
//   const diamond = product?.product;

//   if (!diamond.imageUrl && !diamond.pic2 && !diamond.pic3 && !diamond.pic4 && diamond.shapeId === 1) {
//     return false;
//   } else {
//     return true;
//   }
// };

const toDiamond = (
  product: Product<DiamondsType>,
  dataState: data.State,
  wishListState?: wishList.State,
  wishListId: number = 0
): Diamond => {
  const diamond = product?.product;

  const showToAll = diamond?.showToAll;
  const clarity = dataState.clarities.find(c => c.id === diamond?.clarityId);
  const color = dataState.colors.find(c => c.id === diamond?.colorId);
  const cut = dataState.cuts.find(c => c.id === diamond?.cutId);
  const fluor = dataState.fluors.find(f => f.id === diamond?.fluorId);
  const shape = dataState.shapes.find(s => s.id === diamond?.shapeId);
  const lab = dataState.labs.find(l => l.id === diamond?.labId);
  const polish = dataState.polishes.find(p => p.id === diamond?.polishId);
  const symmetry = dataState.symmetries.find(s => s.id === diamond?.symmetryId);
  const pageId = window.location.pathname;
  const avgCt = diamond?.carats / (!!diamond?.parcelStones ? diamond?.parcelStones : 1);
  const quantity = diamond?.isLine && wishListState?.data?.filter(w => w.itemId === product.id)[0].quantity;
  const ringSize =
    diamond?.isLine && pageId === "/cart"
      ? wishListState?.data?.filter(w => w.itemId === product.id)[0].ringSize
      : undefined;
  const basketParcelQuantity = pageId === "/cart" && !!quantity ? quantity : diamond?.parcelStones; //needed calculation for the parcel stones in the basket
  const vendor = diamond?.vendor || "Unknown";


  return {
    id: product?.id,
    carats: diamond?.carats,
    color: color?.name || "",
    colorVariation: color?.variation || "",
    colorId: diamond?.colorId,
    cut: cut?.name,
    isMelee: false,
    cutId: diamond?.cutId,
    isActive: product?.isActive,
    lab: lab?.name || "",
    labId: diamond?.labId,
    pricePerCarat: diamond?.pricePerCarat,
    shape: shape?.name || "",
    shapeId: diamond?.shapeId,
    status: diamond?.status,
    stockId: diamond?.stockId,
    totalPrice:
      pageId === "/cart" && !!basketParcelQuantity && !diamond?.lineSet
        ? diamond?.pricePerCarat * ((basketParcelQuantity as number) * avgCt)
        : diamond?.totalPrice,
    certificateNumber: diamond?.certificateNo,
    certificateUrl: (diamond?.certificateUrl != null) &&
      (diamond?.vendor != null) &&
      (diamond.vendor !== "Alon") &&
      !diamond.certificateUrl?.includes("cloudfront")
      ? (diamond.certificateNo ? `https://dbrg1tjizxjdx.cloudfront.net/${diamond.certificateNo}.pdf` : "")
      : (diamond?.certificateUrl || ""),
    clarity: clarity?.name,
    clarityId: diamond?.clarityId,
    colorShade: diamond?.colorShade,
    comment: diamond?.comment,
    crownAngle: diamond?.crownAngle,
    crownHeight: diamond?.crownHeight,
    culetSize: diamond?.culetSize,
    depthPercent: diamond?.depthPercent,
    discount: diamond?.discount,
    eyeClean: diamond?.eyeClean,
    fancyIntensity: diamond?.fancyIntensity,
    fluor: fluor?.name,
    fluorId: diamond?.fluorId,
    fluorescence: diamond?.fluorescence,
    fluorescenceColor: diamond?.fluorescenceColor,
    girdle: diamond?.girdle,
    height: diamond?.height,
    image3d: diamond?.image3d,
    set3D: diamond?.set3D,
    setImage: diamond?.setImage,
    imageUrl: !diamond?.imageUrl ? filterDiamondsImage(dataState, diamond) : diamond?.imageUrl,
    pic2: !diamond?.pic2 ? filterDiamondsImage(dataState, diamond) : diamond?.pic2,
    pic3: !diamond?.pic3 ? filterDiamondsImage(dataState, diamond) : diamond?.pic3,
    pic4: !diamond?.pic4 ? filterDiamondsImage(dataState, diamond) : diamond?.pic4,
    imageArray: [diamond?.imageUrl, diamond?.pic2, diamond?.pic3, diamond?.pic4].filter(img => !!img) as string[],
    isHighlighted: diamond?.isHighlighted,
    isMatchingPair: diamond?.isMatchingPair,
    length: diamond?.length,
    overtone: diamond?.overtone,
    width: diamond?.width,
    parcelStones: diamond?.parcelStones,
    pavilionAngle: diamond?.pavilionAngle,
    pavilionDepth: diamond?.pavilionDepth,
    polish: polish?.name,
    polishId: diamond?.polishId,
    rapPrice: diamond?.rapPrice as number,
    symmetry: symmetry?.name,
    symmetryId: diamond?.symmetryId,
    showToAll: showToAll,
    tablePercent: diamond?.tablePercent,
    isFancy: !!color?.name && !["White", "Black"].includes(color?.name),
    memoAccount: diamond?.memoAccount,
    memoPricePerCarat: diamond?.memoPricePerCarat,
    memoTotalPrice: diamond?.memoTotalPrice,
    milky: diamond?.milky,
    hold: diamond?.hold,
    holdUserId: diamond?.holdUserId,
    memoNo: diamond?.memoNo,
    memoDate: diamond?.memoDate,
    memoRap: diamond?.memoRap,
    isLine: diamond?.isLine,
    isSnP: diamond?.isSnP,
    isLabGrown: diamond?.isLabGrown,
    lineSet: diamond?.lineSet,
    isPair: diamond?.isPair,
    wishListId: wishListId,
    tradeShow: diamond?.tradeShow,
    ringSize: ringSize,
    treatment: diamond?.treatment,
    lineSetColor: diamond?.lineSetColor,
    lineSetClarity: diamond?.lineSetClarity,
    vendor: vendor,
  };
};

const toMelee = (product: Product<MeleeStone>): MeleeStone => ({
  ...product.product,
  id: product.id,
  isActive: product.isActive,
});

const toNameDiamond = (product: Product<NameDiamond>): NameDiamond => ({
  ...product.product,
  id: product.id,
  isActive: product.isActive,
});

const toNameDiamondForBasket = (product: NameDiamond, wishListId: number = 0): Diamond => {
  const diamond = product;

  return {
    id: product.id,
    isMelee: false,
    isNameDiamond: true,
    color: diamond.color,
    letter: diamond.letter,
    size: diamond.size,
    isActive: product.isActive,
    imageUrl: product.pic,
    totalPrice: product.totalPrice,
    wishListId: wishListId,
    shape: product.letter,
    stockId: "NYD",
    carats: 0,
    pricePerCarat: 1,
    length: 0,
    width: 0,
    height: 0,
    isMatchingPair: false,
    colorId: 14,
    lab: "",
    labId: 1,
    shapeId: 1,
    status: 1,
    certificateNumber: "",
    certificateUrl: "",
    rapPrice: 0,
    isFancy: false,
    ringSize: undefined,
  };
};

const toMeleeForBasket = (product: MeleeStone | any, wishListId: number = 0): Diamond => {
  const diamond = product;

  return {
    id: product.id,
    isMelee: true,
    carats: diamond.carats,
    color: diamond.color,
    pricePerCarat: diamond.pricePerCarat,
    length: diamond.sizeFrom,
    width: diamond.sizeTo,
    height: 0,
    isMatchingPair: false,
    totalPrice: diamond.pricePerCarat * diamond.carats,
    imageUrl: "/static/media/coming_soon.1cf30592.jpg",
    colorId: 14,
    isActive: product.isActive,
    lab: "",
    labId: 1,
    shape: "Round",
    shapeId: 1,
    status: 1,
    stockId: "Melee",
    certificateNumber: "",
    certificateUrl: "",
    rapPrice: 0,
    isFancy: false,
    wishListId: wishListId,
    ringSize: undefined,
  };
};

const toUser = (profile: IUserProfile): User => {
  const address = profile.addresses[0];

  return {
    id: profile.id,
    addressId: address.id,
    address: address.address,
    approved: profile.approved,
    blocked: profile.blocked,
    businessType: profile.businessType.name,
    businessTypeId: profile.businessType.id,
    city: address.city,
    country: address.country.name,
    countryId: address.country.id,
    email: profile.email,
    emailConfirmed: profile.emailConfirmed,
    federationId: address.federation?.id,
    firstName: address.firstName,
    lastName: address.lastName,
    phone: address.phone,
    primaryColor: profile.primaryColor,
    registrationDate: profile.registrationDate,
    lastLoginDate: profile.lastLoginDate,
    blockedUntil: profile.blockedUntil,
    company: address.company,
    role: profile.role,
    domain: profile.domain,
    markups: profile.markups,
    accesses: profile.accesses,
    domainProvider: profile.domainProvider,
    lastDomainLogin: profile.lastDomainLogin,
    vendors: profile.vendors,
    suspendedVendors: profile.suspendedVendors,
    cells: profile.domainCells,
    discount: profile.discount,
  };
};

const getDiamondColor = (diamond: Diamond): string => {
  if (diamond.color === "" && !diamond.isFancy) {
    return "";
  }
  if (!!diamond.lineSet && diamond.stockId === diamond.lineSet) {
    return diamond.lineSetColor as string;
  }

  if (!diamond.isFancy && !diamond.isMelee) {
    return diamond.colorVariation || (diamond.color as string);
  }

  return `${_.startCase(_.toLower(diamond.fancyIntensity || ""))} ${_.startCase(_.toLower(diamond.overtone || ""))} ${diamond.color !== "NA" ? (diamond.color === "FancyWhite" ? "White" : diamond.color) : ""
    }`;
};

const onWhatsAppShare = (diamond: Diamond, isCertificateEnabled: boolean) => () => {
  const { stockId, image3d, certificateUrl, carats, clarity, lab } = diamond;
  const color = getDiamondColor(diamond);

  const stockIdCheck = stockId ? `Stock ID: ${stockId}` : "";
  const image3dCheck = image3d ? `%0D%0A3D: ${image3d}` : "";
  const certificateUrlCheck = certificateUrl && isCertificateEnabled ? `%0D%0ACert: ${certificateUrl}` : "";
  const caratsCheck = carats ? `%0D%0AWeight: ${carats}` : "";
  const colorCheck = color ? `%0D%0AColor: ${color}` : "";
  const clarityCheck = clarity ? `%0D%0AClarity: ${clarity}` : "";
  const labCheck = lab ? `${lab} Certified` : "";

  window.location.href = `whatsapp://send?text=${stockIdCheck} ${image3dCheck} ${certificateUrlCheck} ${caratsCheck} ${colorCheck} ${clarityCheck} ${labCheck}`;
};

type ToDiamondSetType = {
  diamondsWithSets: AccomType;
  data: DataState;
  misc?: State;
  wishListState?: wishList.State;
  wishListId?: number;
};

const toDiamondSets = ({ diamondsWithSets, data, misc, wishListState, wishListId = 0 }: ToDiamondSetType) => {
  const setsConstructor = () => {
    const uniqueSetNames: string[] = [];
    diamondsWithSets.sets.forEach(d => {
      if (!!d.product.lineSet && !uniqueSetNames.includes(d.product.lineSet)) {
        uniqueSetNames.push(d.product.lineSet);
      }
    });

    const arrayOfSets = uniqueSetNames.map(setName => {
      const diamondsFromSet = diamondsWithSets.sets.filter(d => d.product.lineSet === setName);

      const diamondsFromSetUnselected = diamondsFromSet
        .filter(d => d.id)
        .map(d => misc?.wishListForSets?.includes(d.id) && d.id);

      diamondsFromSetUnselected.map(idset => {
        diamondsFromSet.map((d, index) => (d.id === idset ? diamondsFromSet.splice(index, 1) : ""));
      });

      const createSet = diamondsFromSet.reduce(
        (acc, diamond, i) => {
          if (i === 0) {
            return acc;
          }

          acc.product.totalPrice += diamond.product.totalPrice;
          acc.product.carats += diamond.product.carats;
          acc.product.pricePerCarat += diamond.product.pricePerCarat;
          acc.product.length += diamond.product.length;
          acc.product.width += diamond.product.width;
          acc.product.height += diamond.product.height;
          if (!!diamond.product.parcelStones && !!acc.product.parcelStones) {
            acc.product.parcelStones += diamond.product.parcelStones;
          }
          return acc;
        },
        {
          ...diamondsFromSet[0],
          product: {
            ...diamondsFromSet[0].product,
            stockId: setName,
          },
        }
      );

      const setWithAvgPPC: Product<DiamondsType> = {
        ...createSet,
        product: {
          ...createSet.product,
          pricePerCarat: createSet.product.pricePerCarat / diamondsFromSet.length,
        },
      };
      const diamondSet = toDiamond(setWithAvgPPC, data, wishListState, wishListId);
      return {
        ...diamondSet,
        color: !!diamondSet.lineSetColor ? diamondSet.lineSetColor : "",
        clarity: !!diamondSet.lineSetClarity ? diamondSet.lineSetClarity : "",
        cut: "",
        polish: "",
        fluor: "",
        length: diamondSet.length / diamondsFromSet.length,
        width: diamondSet.width / diamondsFromSet.length,
        height: diamondSet.height / diamondsFromSet.length,
        depthPercent: undefined,
        symmetry: undefined,
        tablePercent: undefined,
      };
    });
    return arrayOfSets;
  };
  return setsConstructor();
};

const basketData = ({ state }: { state: ApplicationState }) => {
  const wishListDiamonds = state.wishList.data || [];
  const allDiamonds = state.diamonds.all || [];
  const data = state.data;

  const meleeDiamonds: any = [];
  wishListDiamonds.forEach(w => {
    if (w.itemType === ProductType.Melee && state.melee.length > 0) {
      const newItem = {
        ...w,
        item: getProduct(w.itemType, w.itemId, state),
      };
      return meleeDiamonds.push(toMeleeForBasket(newItem.item as MeleeStone, w.id));
    }
  });

  const nameDiamonds: any = [];
  wishListDiamonds.forEach(w => {
    if (w.itemType === ProductType.NameDiamond && state.nameDiamonds.length > 0) {
      const newItem = {
        ...w,
        item: getProduct(w.itemType, w.itemId, state),
      };

      return nameDiamonds.push(toNameDiamondForBasket(newItem.item as NameDiamond, w.id));
    }
  });

  const ordinaryDiamond: Diamond[] = [];
  const uniqueSetNames: string[] = [];
  const wishListDiamondsIds = wishListDiamonds.map(diamond => diamond.itemId);
  wishListDiamonds.forEach(w => {
    if (w.itemType === ProductType.Diamond && allDiamonds.length > 0) {
      const diamond = allDiamonds.filter(d => d.product.status !== 3).find(d => d.id === w.itemId);
      if (!!diamond?.product.lineSet && !uniqueSetNames.includes(diamond.product.lineSet)) {
        const setOfDiamonds = allDiamonds.filter(
          d =>
            diamond.product.lineSet === d.product.lineSet &&
            wishListDiamondsIds.includes(d.id) &&
            d.product.status !== 3
        );
        setOfDiamonds.length > 0 && uniqueSetNames.push(diamond.product.lineSet);
        return setOfDiamonds !== null
          ? ordinaryDiamond.push(
            toDiamondSets({
              diamondsWithSets: { sets: setOfDiamonds, fancy: [] },
              data,
              wishListState: state.wishList,
              wishListId: w.id,
            })[0]
          )
          : null;
      }
      if (!!diamond?.product.lineSet) {
        return;
      }
      ordinaryDiamond.push(toDiamond(diamond as Product<DiamondsType>, data, state.wishList, w.id));
    }
  });

  return [...meleeDiamonds, ...ordinaryDiamond, ...nameDiamonds];
};

const isMemoHoldedByUser = (user: IUserProfile | null, diamond: Product<DiamondsType>): boolean => {
  if (diamond.product.status !== DiamondStatusEnum.Memo) {
    return true;
  }

  if (diamond.product.showToAll !== null) {
    return true;
  }
  if (
    diamond.product.status === DiamondStatusEnum.Memo &&
    ((user !== null && user.addresses[0].company === diamond.product.memoAccount) ||
      diamond.product.memoAccount === `${user?.addresses[0].firstName} ${user?.addresses[0].lastName}`)
  ) {
    return true;
  }
  return false;
};

export {
  calculateMeleePrice,
  getDiamondColor,
  getDiamondName,
  getDiamondStockId,
  getMeleeName,
  renderMeleeSize,
  renderOrderItemDescription,
  renderOrderItemStockId,
  renderOrderItemShape,
  renderOrderItemPPC,
  renderOrderItemColor,
  renderOrderItemClarity,
  renderOrderItemWeight,
  renderOrderItemMeasurements,
  renderOrderItemLab,
  renderOrderItemQty,
  renderOrderItemUrl,
  toDiamond,
  toMelee,
  toNameDiamond,
  toUser,
  onWhatsAppShare,
  toMeleeForBasket,
  basketData,
  toDiamondSets,
  getMeleeQTY,
  isMemoHoldedByUser,
};
